import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Isaac Gierard</h1>
    <h2>Interactive Developer</h2>
    <p>
      Hi my name is Isaac Gierard. I am an interactive developer with over 15
      years of professional experience in programming and design.
    </p>
    <p>
      I am a digital technical/creative who uses technology to create beautiful
      audio visual experiences that convey concepts, information, and emotion. I
      use an ever increasing variety of tools and techniques with a focus on
      collaboration with others to achieve the best results for the businesses
      and individuals I work with.
    </p>
  </Layout>
)

export default IndexPage
